import { FlashSale } from '@/components/FlashSale'
import { HomeSlider } from '@/components/HomeSlider'
import ProductTrending from '@/components/ProductTrending'
import { BannerType } from '@/constants'
import { useFlashSale } from '@/containers/FlashSaleContainer'
import { usePromotion } from '@/hooks/usePromotion'
import { Meta } from '@/layouts/Meta'
import { Main } from '@/templates/Main'
import type { Keywords, SystemConfig } from '@/types'
import {
  type BannerList,
  type Promotion,
  type PromotionProductCarousel,
  type Setting,
} from '@/types'
import { getTranslate, setContext } from '@/utils/api-interceptors'
import {
  getBannerSSR,
  getBottomMenu,
  getConfigSSR,
  getExclusiveDeal,
  getFlashSale,
  getHomepageProductCarousel,
  getKeyword,
  getMenus,
  getProductTrending,
  getQuickMenu,
  getSaleRegion,
  getYourPromotions,
} from '@/utils/endpoint'
import { getCookies } from 'cookies-next'
import { type GetServerSideProps } from 'next'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
const BlogList = dynamic(() => import('@/components/BlogList'), {
  ssr: false,
})
const HomeBanner = dynamic(() => import('@/components/HomeBanner'), {
  ssr: false,
})
const ProductCarousel = dynamic(() => import('@/components/ProductCarousel'), {
  ssr: false,
})
const Recommend = dynamic(() => import('@/components/Recommend'), {
  ssr: false,
})
type HomeProps = {
  setting?: Setting
  promotion: Promotion
  systemConfig: SystemConfig
  exclusiveDeal: Promotion | null
  banner: BannerList[]
  yourPromotions: Promotion[]
  productCarousel: PromotionProductCarousel[]
  keywords: Keywords
}
const Home = ({
  promotion,
  banner,
  yourPromotions,
  systemConfig,
  productCarousel,
  exclusiveDeal,
}: HomeProps) => {
  const t = useTranslations('Homepage')
  const { flashSale } = useFlashSale()
  const router = useRouter()
  const { products, categoryActive, setCategoryActive } =
    usePromotion(yourPromotions)
  const middleBanner = useMemo(() => {
    return banner.filter(banner => {
      return banner.type === BannerType.HOME && banner.status === 1
    })
  }, [banner])
  const mainSlider = useMemo(() => {
    return banner.filter(banner => {
      return banner.type === BannerType.MAIN && banner.status === 1
    })
  }, [banner])
  const backgroundHomepage = useMemo(() => {
    return banner?.find(banner => {
      return banner?.type === BannerType.BACKGROUND && banner?.status === 1
    })?.items[0]?.image
  }, [banner])
  return (
    <Main
      meta={
        <Meta
          title={getTranslate(systemConfig?.seoMetaTitle)}
          description={getTranslate(systemConfig?.seoMetaDescription)}
          imageUrl={systemConfig?.seoImageDefault}
          imageAlt={systemConfig?.websiteBrandName}
          canonical={
            process.env.NEXT_PUBLIC_URL_WEBSITE + router.asPath ||
            process.env.NEXT_PUBLIC_URL_WEBSITE
          }
          noFollow={false}
          noIndex={false}
          mobileAlternate={{
            media: 'only screen and (max-width:991px))',
            href:
              process.env.NEXT_PUBLIC_URL_WEBSITE_MOBILE + router.asPath ||
              process.env.NEXT_PUBLIC_URL_WEBSITE_MOBILE ||
              '',
          }}
        />
      }
      backgroundImage={backgroundHomepage || ''}
      page='homepage'
    >
      <div className='max-w-content w-full overflow-hidden'>
        <HomeSlider sliders={mainSlider[0]?.items} />
      </div>
      <div className='container mx-auto w-full pt-8'>
        <ProductTrending dataProductTrend={promotion} />
        <HomeBanner
          banner={middleBanner[0]?.items}
          layout={middleBanner[0]?.layout}
          data={middleBanner[0]}
        />
        {exclusiveDeal && <ProductCarousel data={exclusiveDeal} index={0} />}
        {systemConfig && systemConfig.flashSaleHomepage == 'true' ? (
          <FlashSale flashSale={flashSale} />
        ) : (
          ''
        )}
        {productCarousel &&
          productCarousel
            ?.sort((a, b) => a.order - b.order)
            ?.map((e, i) => {
              if (e && e.products.length > 0) {
                return <ProductCarousel data={e} index={i} key={i} />
              }
            })}

        <Recommend
          title={getTranslate(systemConfig?.titleProductForYou)}
          subTitle={getTranslate(systemConfig?.subTitleProductForYou)}
          setCategoryActive={setCategoryActive}
          categoryActive={categoryActive}
          products={products}
          recommendCategories={yourPromotions}
        />
        <BlogList title={t('blogListTitle')} subTitle={t('blogListSubTitle')} />
        <HomeBanner
          banner={middleBanner[1]?.items}
          layout={middleBanner[1]?.layout}
        />
      </div>
    </Main>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale,
}) => {
  const { token, language, messages } = await setContext(
    getCookies({ req, res }),
    {
      language: locale,
    }
  )

  const [
    promotion,
    flashSale,
    yourPromotions,
    menus,
    quickMenu,
    banner,
    bottomMenu,
    systemConfig,
    productCarousel,
    exclusiveDeal,
    keywords,
    regions,
  ] = await Promise.all([
    getProductTrending(),
    getFlashSale(),
    getYourPromotions(),
    getMenus(),
    getQuickMenu(),
    getBannerSSR(),
    getBottomMenu(),
    getConfigSSR(),
    getHomepageProductCarousel(),
    getExclusiveDeal(),
    getKeyword(),
    getSaleRegion(),
  ])
  return {
    props: {
      yourPromotions,
      messages,
      promotion,
      flashSale,
      menus,
      quickMenu,
      bottomMenu,
      banner,
      language,
      systemConfig,
      productCarousel,
      exclusiveDeal,
      keywords,
      regions,
      token: token || null,
    },
  }
}
